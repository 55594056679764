// Openlayers
import OlMap from 'ol/Map';
import OlBaseLayer from 'ol/layer/Base';

// Lib
import authHeader from '@/lib/api/authHeader';

// Types
import { IHeaders } from '@/@types/lib/api';
import {
  GFIResponseType,
  GFILayerFeatureType,
  GFIFeatureType,
  LayerProps,
} from '@/@types/services/gsService';

export interface IGSService {
  getFeatureInfo: (
    map: OlMap,
    point: number[],
    gsLayers: Array<OlBaseLayer>,
    gsLayerNameOverride?: string[] // Used with profiles, where there is no 'layer' attribute on the featue
  ) => Promise<GFIResponseType | null>;

  getLegend: (
    layerProperties: LayerProps,
    language: string,
    bgColor: string,
    fontColor: string
  ) => string;
}

const gsService: IGSService = {
  getFeatureInfo,
  getLegend,
};

const INFO_FORMAT_JSON = 'application/json';
const INFO_FORMAT_HTML = 'text/html';
const INFO_FORMAT_TEXT = 'text/plain';

function getFeatureInfo(
  map: OlMap,
  point: number[],
  gsLayers: Array<OlBaseLayer>,
  gsLayerNameOverride?: string[]
): Promise<GFIResponseType | null> {
  const headers: IHeaders = authHeader();
  headers['Content-Type'] = 'text/plain';
  headers.Accept = 'text/html';

  const mapSize = map.getSize();
  const extent = map.getView().calculateExtent(mapSize);

  const requestOptions = {
    method: 'GET',
    headers,
  };

  // TODO: need to define in which layer are we seraching for.
  const queryLayers = gsLayerNameOverride ? gsLayerNameOverride : gsLayers.map((x) => x.get('layer'));
  const queryStyles = gsLayers
    .map((x) => x.get('styles'))
    .filter((x) => x && x !== '');

  const layers = queryLayers.join(',');
  const styles = queryStyles.join(',');

  const gsUrl = process.env.REACT_APP_GEOSERVERPATH;
  const url =
    `${gsUrl}?SERVICE=WMS` +
    `&VERSION=1.1.1` +
    `&REQUEST=GetFeatureInfo` +
    `&LAYERS=${layers}` +
    `&STYLES=${styles}` +
    `&SRS=EPSG:3857` +
    `&BBOX=${extent}` +
    `&WIDTH=${Array.isArray(mapSize) ? mapSize[0] : ''}` +
    `&HEIGHT=${Array.isArray(mapSize) ? mapSize[1] : ''}` +
    `&QUERY_LAYERS=${layers}` +
    `&FORMAT=image/png` +
    `&INFO_FORMAT=${INFO_FORMAT_JSON}` +
    `&FEATURE_COUNT=100` +
    `&X=${point[0].toFixed(0)}` +
    `&Y=${point[1].toFixed(0)}` +
    `&BUFFER=10` +
    `&authkey=${localStorage.getItem('auth_token')}`;

  return fetch(url, requestOptions)
    .then((response) =>
      response.text().then((text) => {
        if (!response.ok) {
          console.log(response);
          return null;
        }
        const js = JSON.parse(text);
        const data = parseFeatures(js.features as GFIFeatureType[]);
        return data;

        // const data = text && JSON.parse(text)
        // if (!response.ok) {
        //   const error = (data && data.message) || response.statusText;
        //   return Promise.reject(error);
        // }

        // return data;
      })
    )
    .catch((error) => null);
}

function parseFeatures(features: GFIFeatureType[]) {
  const layerFeatures: Array<GFILayerFeatureType> = features.map((feature) => {
    if (feature.id === '') {
      const layerId = Object.prototype.hasOwnProperty.call(feature.properties, 'DSM') ? 'dsm' : 'dem';
      const properId = Object.prototype.hasOwnProperty.call(feature.properties, 'DSM') ? 'dsm' : 'dem';

      Object.keys(feature.properties).forEach(propName => {
        if (feature.properties[propName] === null) {
          delete feature.properties[propName];
        }
        
      });
      return {
        layerId,
        properId,
        properties: feature.properties,
        geometry_name: feature.geometry_name ? feature.geometry_name : 'geom',
        geometry: feature.geometry,
      } as GFILayerFeatureType;
    }
    const ind = feature.id.indexOf('.');
    const layerId = feature.id.slice(0, ind);
    const properId = feature.id.slice(ind + 1);

    Object.keys(feature.properties).forEach(propName => {
      if (feature.properties[propName] === null) {
        delete feature.properties[propName];
      }
    })

    return {
      layerId,
      properId,
      properties: feature.properties,
      geometry_name: feature.geometry_name,
      geometry: feature.geometry,
    } as GFILayerFeatureType;
  });
  return sortInLayers(layerFeatures);
}

function sortInLayers(object: Array<GFILayerFeatureType>) {
  const resp: GFIResponseType = {};

  object.forEach(feature => {
    const newFeat: GFIFeatureType = {
      id: feature.properId,
      properties: feature.properties,
      geometry: feature.geometry,
      geometry_name: feature.geometry_name,
    };

    if (resp[feature.layerId]) {
      resp[feature.layerId].push(newFeat);
    } else {
      resp[feature.layerId] = [newFeat];
    }
  })
  return resp;
}

const gsApiUrl = process.env.REACT_APP_GEOSERVERPATH;

const defaultLegendParams = {
  width: 20,
  height: 20,
  rule: '',
  style: '',
  options: 'layout:vertical;labelMargin:7;dx:7;',
};

function getLegend(
  layerProperties: LayerProps,
  language: string,
  bgColor: string,
  fontColor: string
) {
  const layerName = layerProperties.layer;
  const width = layerProperties.legend_width
    ? layerProperties.legend_width
    : defaultLegendParams.width;
  const height = layerProperties.legend_height
    ? layerProperties.legend_height
    : defaultLegendParams.height;
  const rule = layerProperties.legend_rule
    ? layerProperties.legend_rule
    : defaultLegendParams.rule;
  const style = layerProperties.legend_style
    ? layerProperties.legend_style
    : layerProperties.styles
    ? layerProperties.styles
    : defaultLegendParams.style;
  const options = layerProperties.legend_options
    ? layerProperties.legend_options
    : defaultLegendParams.options;

  return (
    `${gsApiUrl}?REQUEST=GetLegendGraphic&VERSION=1.0.0&` +
    `FORMAT=image/png&` +
    `WIDTH=${width}&` +
    `HEIGHT=${height}&` +
    `LAYER=${layerName}&` +
    `RULE=${rule}&` +
    `STYLE=${style}&` +
    `LANGUAGE=${language}&` +
    `legend_options=bgColor:0x${bgColor};fontColor:0x${fontColor};${options}` +
    `&authkey=${localStorage.getItem('auth_token')}`
  );
}

export default gsService;
